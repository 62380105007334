<template>
  <div class="modal-wrapper">
    <v-modal
      :name="name"
      height="auto"
      :scrollable="true"
      :width="640"
      :adaptive="true"
    >
      <div class="v--modal--header">
        <button class="v--modal-close" @click="$modal.hide(`${name}`)">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30 12.0143L27.9857 10L20 17.9857L12.0143 10L10 12.0143L17.9857 20L10 27.9857L12.0143 30L20 22.0143L27.9857 30L30 27.9857L22.0143 20L30 12.0143Z"
              fill="#1E2128"
            />
          </svg>
        </button>
      </div>
      <div class="v--modal--body">
        <slot></slot>
      </div>
    </v-modal>
  </div>
</template>

<script>
export default {
  name: "v-modal-wrapper",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variables.scss";
@import "../styles/_functions.scss";
@import "../styles/_mixins.scss";

.modal-wrapper {
  position: absolute;
  left: -9999em;
}

.vm {
  &--modal {
    border-radius: $border-radius !important;
    padding: rem(space(9)) !important;
    box-sizing: border-box;
    max-width: 100% !important;
    box-shadow: none !important;

    @include mobile() {
      padding: rem(space(9)) rem(space(5)) !important;
    }
  }

  &--overlay {
    background: $overlay !important;
    overflow: auto;
  }
}

.v--modal {
  &--header {
    position: absolute;
    top: rem(space(6));
    right: rem(space(6));

    @include mobile() {
      top: rem(space(4));
      right: rem(space(4));
    }
  }

  &-close {
    cursor: pointer;
    appearance: none;
    outline: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    transition: opacity $fx;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
